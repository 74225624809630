$palettes: (
  NTDarkGrey: (
    0: #F9F9F9,
    25: #FAFAFA,
    50: #F6F6F6,
    100: #EAEAEA,
    200: #CECECE, // text.light
    300: #B1B1B1,
    400: #959595,
    500: #808182, // button.secondaryBoxShadow
    600: #6B6E6F,
    700: #565A5C, // text.main
    800: #4A4D4F, // text.dark
    900: #3D4042,
  ),
  NTGreen: (
    100: #DFE8E5,
    200: #BDD0C9,
    300: #9AB8AE,
    400: #78A093,
    500: #568777,
    600: #336F5C,
    700: #115740,
    800: #0D4936,
    900: #093B2B,
  ),
  NTGold: (
    100: #F2EBD7,
    200: #E1D4AE,
    300: #D0BE85,
    400: #BFA85B,
    500: #AE9132,
    600: #A8872A,
    700: #A27D23,
    800: #9C731C,
    900: #966914,
  ),
  NTWarmGrey: (
    100: #F7F6F3,
    200: #EFEDE7,
    300: #E7E4DC,
    400: #DEDBD0,
    500: #D6D2C4,
    600: #CBC6B3,
    700: #C0B9A2,
    800: #B5AD91,
    900: #AAA080,
  ),
  darkGreen: (
    100: #E2FCE3, // secondary.light
    200: #B8E1BA,
    300: #8EC791,
    400: #64AC68, // secondary.main
    500: #3A913F,
    600: #2D8031,
    700: #206F23,
    800: #125D15, // secondary.dark
    900: #054C07,
  ),
  green: (
    100: #E6F7C4,
    200: #C6E396,
    300: #A5CF68,
    400: #85BB39,
    500: #64A70B,
    600: #5D970C,
    700: #56870E,
    800: #4F760F, // text.positive
    900: #486610,
  ),
  lightGreen: (
    100: #FCFCC5,
    200: #EAEC94,
    300: #D9DD63,
    400: #C7CD31,
    500: #B5BD00,
    600: #A4AA03,
    700: #949807,
    800: #83850A,
    900: #72720D,
  ),
  yellow: (
    100: #FFF4C0,
    200: #FFDF84,
    300: #FCD76E,
    400: #FDCF4B,
    500: #FABE0F,
    600: #E5AD08,
    700: #D09D07,
    800: #B98A00,
    900: #A0761C,
  ),
  darkBlue: (
    100: #CBEAFA,
    200: #86D2F9,
    300: #4AB0E5,
    400: #2E8FC2,
    500: #0A699B,
    600: #165F86,
    700: #1C5472,
    800: #104866,
    900: #063D5B,
  ),
  aqua: (
    100: #E1FCFF,
    200: #A2EAF4,
    300: #68D2D8,
    400: #36B4BC,
    500: #00A0AA,
    600: #088B94,
    700: #0A7A82, // primary.main
    800: #05676E, // primary.dark
    900: #085459,
  ),
  teal: (
    100: #E9FFF9,
    200: #D0F4EB, // primary.light
    300: #B5E3D8,
    400: #8AD2C6,
    500: #64C8AF,
    600: #49B69B,
    700: #37A085,
    800: #1F8A6F,
    900: #12745B,
  ),
  purple: (
    100: #FFEEFD,
    200: #E3C1DE,
    300: #C489BB,
    400: #AC5D9F,
    500: #8E3A80,
    600: #813275,
    700: #752A6A,
    800: #68215E,
    900: #5B1953,
  ),
  red: (
    100: #FFE8E6,
    200: #F7C2BB,
    300: #F57D69,
    400: #F5634D,
    500: #E04E39,
    600: #D04532,
    700: #BF3B2A,
    800: #AA2D28,
    900: #941A0D,
  ),
  orange: (
    100: #FFEEDE,
    200: #F9CFA7,
    300: #FBA755,
    400: #FA9619,
    500: #E97C05,
    600: #D77200,
    700: #BF5E00,
    800: #AC5300,
    900: #994900,
  )
)