// Taken from the palette of angular material: https://github.com/angular/material2/blob/master/src/lib/core/theming/_palette.scss
$mat-grey-0: #fff;
$mat-grey-50: #fafafa;
$mat-grey-100: #f5f5f5;
$mat-grey-200: #eee;
$mat-grey-300: #e0e0e0;

// Taking primary and accent from the indigo / pink theme: https://github.com/angular/material2/blob/master/src/lib/core/theming/prebuilt/indigo-pink.scss
$mat-indigo-500: #3f51b5;
$mat-pink-A200: #ff4081;
$mat-pink-50: #fce4ec;
$mat-indigo-50: #e8eaf6;

// NT Colors
$mat-green-500: #5d970c;

// opacity values used for black values, taken from material spec
$foreground-opacity: 0.87 !default;
$secondary-foreground-color-opacity: 0.54 !default;
$disabled-foreground-color-opacity: 0.38 !default;

/////////////////////////////////////////////////////////////////////////////////////////////////////
// The variables below can be overridden to adjust the look up to the app color scheme
/////////////////////////////////////////////////////////////////////////////////////////////////////

// Sizing
$grid-size: 8px !default;
$icon-size: 14px !default;
$header-height: 32px !default;
$row-height: 40px !default;
$row-border-width: 1px !default;
$column-select-indent-size: $grid-size + $icon-size !default;
$row-group-indent-size: $grid-size * 3 + $icon-size !default;
$list-item-height: $grid-size * 5;

// Fonts
$font-family: "Roboto", sans-serif !default; // legacy: font
$font-size: 13px !default;
$secondary-font-family: "Roboto", sans-serif !default; //font-secondary
$secondary-font-size: 12px !default; // font-secondary-size
$secondary-font-weight: 700 !default; // font-secondary-weight

// Colors
$foreground-color: rgba(#000, $foreground-opacity) !default; // foreground
$secondary-foreground-color: rgba(
  #000,
  $secondary-foreground-color-opacity
) !default;
$disabled-foreground-color: rgba(
  #000,
  $disabled-foreground-color-opacity
) !default;
$border-color: $mat-grey-300 !default;
$primary-color: $mat-green-500 !default;
$accent-color: $mat-pink-A200 !default;
$icon-color: #333 !default;
$background-color: $mat-grey-0 !default;
$tool-panel-background-color: $mat-grey-50 !default;
$chip-background-color: $mat-grey-300 !default;
$range-selection-background-color: $mat-indigo-50 !default;
$range-selection-highlight-color: $mat-pink-50 !default;

// Misc
$card-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
$card-radius: 2px;

// delta changes
$value-change-delta-up-color: #43a047 !default;
$value-change-delta-down-color: #e53935 !default;
$value-change-value-highlight-background-color: #00acc1 !default;

@import "../../node_modules/ag-grid-community/src/styles/ag-grid";
@import "../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat.scss";

.ag-theme-views,
.ag-theme-material,
.ag-frequency-grid-views {
  @include ag-theme-material(
    (
      grid-size: $grid-size,
      icon-size: $icon-size,
      header-height: $header-height,
      row-height: $row-height,
      list-item-height: $list-item-height,
      column-select-indent-size: $column-select-indent-size,
      row-group-indent-size: $row-group-indent-size,
      font-family: $font-family,
      font-size: $font-size,
      foreground-color: $foreground-color,
      secondary-foreground-color: $secondary-foreground-color,
      disabled-foreground-color: $disabled-foreground-color,
      background-color: $background-color,
      border-color: $border-color,
      chip-background-color: $chip-background-color,
      range-selection-background-color: $range-selection-background-color,
      range-selection-highlight-color: $range-selection-highlight-color,
      material-accent-color: $accent-color,
      material-primary-color: $primary-color,
      card-radius: $card-radius,
      card-shadow: $card-shadow,
      full-width-tabs: true,
      value-change-delta-up-color: $value-change-delta-up-color,
      value-change-delta-down-color: $value-change-delta-down-color,
      value-change-value-highlight-background-color:
        $value-change-value-highlight-background-color,
    )
  );

  .ag-tab-selected {
    border-bottom: 2px solid $primary-color;
  }

  .ag-menu-separator {
    height: 1px;
    background-color: $mat-grey-300;
  }

  .ag-header-cell-resize {
    width: 1.6rem;
  }

  .ag-group-expanded {
    margin-right: 0px !important;
  }

  .ag-group-contracted {
    margin-right: 0px !important;
  }

  .ag-cell-label-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 3.2rem;
  }

  .ag-row.ag-row-last {
    border-bottom-style: none;
  }

  .ag-row:not(.ag-row-first) {
    border-top-style: none;
  }

  .ag-standard-button {
    background-color: transparent;
    text-transform: uppercase;
  }

  .ag-icon {
    color: $icon-color;
    opacity: $foreground-opacity;
    font-size: $icon-size;
    width: 18px;
  }

  .ag-header-icon {
    font-size: $icon-size;
  }

  .ag-icon-contracted,
  .ag-icon-tree-closed {
    content: url(../assets/img/ag-icons/contracted.svg);
  }

  .ag-icon-expanded,
  .ag-icon-tree-open {
    content: url(../assets/img/ag-icons/expanded.svg);
  }

  .ag-icon-small-right,
  .ag-icon-tree-closed {
    content: url(../assets/img/ag-icons/small-right.svg);
  }

  .ag-picker-field-wrapper {
    min-height: 3.2rem !important;
    height: 3.2rem !important;
    border-radius: 2px;

    &:focus {
      box-shadow: 0 0 0 1px $primary-color;
    }
  }

  .ag-cell-highlight {
    background-color: $range-selection-highlight-color !important;
  }

  .ag-cell-highlight-animation {
    transition: background-color 1s;
  }

  .ag-row-drag {
    background-position-y: center;
  }

  .ag-column-drag {
    background-position-y: center;
  }

  .ag-column-drop-cell-drag-handle {
    margin-left: 0rem;

    .ag-icon-grip {
      display: none;
    }
  }

  // we do not want to color the range color when the cell is also focused
  .ag-cell-range-selected-1:not(.ag-cell-focus) {
    background-color: $range-selection-background-color;
  }

  .ag-cell-range-selected-2:not(.ag-cell-focus) {
    background-color: darken($range-selection-background-color, 4);
  }

  .ag-cell-range-selected-3:not(.ag-cell-focus) {
    background-color: darken($range-selection-background-color, 8);
  }

  .ag-cell-range-selected-4:not(.ag-cell-focus) {
    background-color: darken($range-selection-background-color, 12);
  }

  .ag-side-bar {
    border-bottom: 0;
    border-right: 0;
    border-top: 0;

    .ag-side-buttons button {
      border: 0;
      color: $secondary-foreground-color;
      font-family: $secondary-font-family;
      font-size: $secondary-font-size;
      font-weight: $secondary-font-weight;
      background: transparent;
    }

    .ag-side-buttons .ag-side-button button {
      background-color: transparent;
      border-width: 0;
    }

    .ag-side-buttons .ag-selected button {
      border-left: 2px solid $primary-color;
      background-color: $tool-panel-background-color;
      margin-left: -2px;
      padding-left: 1px;
    }

    .ag-filter-toolpanel-body {
      background-color: $background-color;
    }
  }

  .ag-rtl .ag-side-bar {
    .ag-side-buttons .ag-selected button {
      border-left: 0;
      margin-left: 0;
      padding-left: 0;

      border-right: 2px solid $primary-color;
      margin-right: -2px;
      padding-right: 1px;
    }
  }

  .ag-row {
    border-width: $row-border-width;
    color: #3D4042 !important;
    -webkit-font-smoothing: antialiased;
  }

  .ag-row-last {
    border-bottom-style: none;
    border-bottom: none;
  }

  .ag-ltr .ag-column-drop-horizontal {
    padding-left: 0.6rem;
  }

  .ag-header-cell-menu-button .ag-icon-menu {
    height: auto !important;
  }

  .ag-column-drop-horizontal {
    transition: max-height 0.15s;
    height: 3.2rem;
    background-color: rgb(247, 246, 244);
    color:  #3D4042 !important;

    .ag-column-drop-horizontal-icon {
      display: none;
    }

    .ag-column-drop-horizontal-empty-message {
      line-height: 3.2rem;
    }

    .ag-column-drop-horizontal-list {
      flex: 1;
      display: flex;
      align-items: center;

      .ag-column-drop-horizontal-cell {
        max-height: 2.2rem;
        min-height: 2.2rem;
        margin: 0;
        background-color: white;
        border: 1px solid color("NTWarmGrey", 400);

        .ag-column-drag {
          display: none;
        }

        .ag-column-drop-horizontal-cell-text {
          line-height: 2.2rem;
          margin-right: 0;
        }

        .ag-column-drop-horizontal-cell-button {
          min-width: 0;
          margin: 0 0.5rem 0 0.8rem;
          cursor: pointer;
        }
      }
    }
  }

  .ag-status-bar {
    background-color: rgb(247, 246, 244);
  }
}
