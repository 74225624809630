$color_1: #3D4042;
$color_2: #05676E;
$color_3: #297DB0;
$color_4: #B1B1B1;
$font_family_1: 'Roboto Regular', sans-serif;
$font_family_2: 'Roboto Medium', sans-serif;
$background_color_1: #cecece;
$background_color_2: $color_1;
$background_color_3: #FFFFFF;
$background_color_4: #c4ebff78;
$background_color_5: #E2FCE3;
$background_color_6: #F1F5F4;
$background_color_7: #86D2F9;
$border_right_color_1: rgb(224, 224, 224);
$green_500: #64A70B;

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Roboto Regular", sans-serif;
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100%;
}

.micro-app-container
 {
	padding-top: 4.8rem;
	min-height: 100%;
	min-width: 100%;
	overflow: auto;
	display: none;

	&.active {
		display: flex;
	}
}

* {
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
			background-clip: content-box;
			opacity: 0.5;
		}
		&::-webkit-scrollbar-thumb {
			width: 8px;
			height: 8px;
			background-clip: content-box;
			opacity: 0.5;
			background-color: $background_color_1;
			&:hover {
				opacity: 1.0;
				background-color: $background_color_2;
				background-clip: content-box;
			}
		}
		box-sizing: border-box;
		outline: none;
	}
button {
	cursor: pointer;
	font-size: 1.2rem;
	transition: all 0.125s cubic-bezier(0, 0, 0.25, 1);
	&:disabled {
		cursor: default;
	}
}
a {
	cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font_family_1;
	margin-bottom: 0px;
}
h1 {
	font-size: 3.6rem;
}
h2 {
	font-size: 3.0rem;
}
h3 {
	font-size: 2.4rem;
}
h4 {
	font-size: 1.6rem;
}
h5 {
	font-size: 1.4rem;
}
h6 {
	font-size: 1.2rem;
}
p {
	font-family: $font_family_1;
	font-size: 1.3rem;
	color: $color_1;

	&.details {
		font-weight: 600;
	}
}
[class^="icon--"],
[class*=" icon--"],
.dds-icons,
.material-icons {
	font-size: 1.5rem;
	transition: all .125s cubic-bezier(0, 0, .25, 1);
	display: inline-flex;
	vertical-align: middle;
}

p.details {
	font-weight: 600;
}

.hidden {
	display: none;
}

.width-auto {
	width: auto !important;
}
