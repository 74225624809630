/*!
 * froala_editor v4.0.10 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2022 Froala Labs
 */

.fr-clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.examples-variante > a {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif; }

.sc-cm-holder > .sc-cm {
  border-top: 5px solid #222222 !important;
  padding: 0 !important;
  line-height: 200% !important; }

.sc-cm .sc-cm__item.examples-variante.sc-cm__item_active > a > i {
  display: none !important; }

.sc-cm .sc-cm__item.examples-variante > a > i {
  display: none !important; }

.sc-cm__item_dropdown .i-icon {
  display: none !important; }

.sc-cm__item_dropdown .i-icon::before {
  display: none !important; }

.sc-cm::before {
  display: none !important; }

div.sc-cm-holder.sc-cm_show > ul > li.sc-cm__item.sc-cm__item_dropdown.sc-cm__item_arrow > div > ul {
  border-style: none !important;
  padding: 0 !important; }

.sc-cm__item_dropdown:hover > a, .sc-cm a:hover {
  background-color: #ebebeb !important; }

.sc-cm__item_active > a, .sc-cm__item_active > a:hover, .sc-cm a:active, .sc-cm a:focus {
  background-color: #d6d6d6 !important; }

.sc-cm__item > a {
  line-height: 200% !important; }

.sc-cm-holder > .sc-cm:before {
  background-color: #ebebeb !important; }

.sc-cm-holder {
  display: none; }
