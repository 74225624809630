@import url("https://cdn.northerntrust.com/common/webfonts/nt-dds-icons/latest/nt-dds-icons.css");

/* roboto-light */
@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: 300;

  src: local('Roboto Light'), local('Roboto-Light'),
       url('https://cdn.northerntrust.com/common/webfonts/roboto/Roboto-Light.woff2') format('woff2'),
       url('https://cdn.northerntrust.com/common/webfonts/roboto/Roboto-Light.woff') format('woff');
}

/* roboto-regular */
@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: 400;

  src: local('Roboto'), local('Roboto-Regular'),
       url('https://cdn.northerntrust.com/common/webfonts/roboto/Roboto-Regular.woff2') format('woff2'),
       url('https://cdn.northerntrust.com/common/webfonts/roboto/Roboto-Regular.woff') format('woff');
}

/* roboto-medium */
@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 500;

  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('https://cdn.northerntrust.com/common/webfonts/roboto/Roboto-Medium.woff2') format('woff2'),
       url('https://cdn.northerntrust.com/common/webfonts/roboto/Roboto-Medium.woff') format('woff');
}

/* roboto-bold */
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 700;

  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('https://cdn.northerntrust.com/common/webfonts/roboto/Roboto-Bold.woff2') format('woff2'),
       url('https://cdn.northerntrust.com/common/webfonts/roboto/Roboto-Bold.woff') format('woff');
}

/* roboto-mono-regular */
@font-face {
  font-family: 'Roboto Mono Regular';
  font-style: normal;
  font-weight: 400;

  src: local('Roboto Mono Regular'), local('Roboto-Mono-Regular'),
    url('https://cdn.northerntrust.com/common/webfonts/roboto/roboto-mono-v12-latin-regular.woff2') format('woff2'),
    url('https://cdn.northerntrust.com/common/webfonts/roboto/roboto-mono-v12-latin-regular.woff') format('woff');
}

/* roboto-mono-bold */
@font-face {
  font-family: 'Roboto Mono Bold';
  font-style: normal;
  font-weight: 600;

  src: local('Roboto Mono Bold'), local('Roboto-Mono-Bold'),
    url('https://cdn.northerntrust.com/common/webfonts/roboto/roboto-mono-v12-latin-600.woff2') format('woff2'),
    url('https://cdn.northerntrust.com/common/webfonts/roboto/roboto-mono-v12-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'nt-dds-icons';
  src:
    url('https://cdn.northerntrust.com/common/webfonts/nt-dds-icons/latest/nt-dds-icons.ttf') format('truetype'),
    url('https://cdn.northerntrust.com/common/webfonts/nt-dds-icons/latest/nt-dds-icons.woff2') format('woff2'),
    url('https://cdn.northerntrust.com/common/webfonts/nt-dds-icons/latest/nt-dds-icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

i, .icomoon-liga {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nt-dds-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--account_box:before {
  content: "\e900";
}
.icon--person_box:before {
  content: "\e900";
}
.icon--account_circle:before {
  content: "\e901";
}
.icon--person_circle:before {
  content: "\e901";
}
.icon--account:before {
  content: "\e902";
}
.icon--person:before {
  content: "\e902";
}
.icon--account_add:before {
  content: "\e903";
}
.icon--person_add:before {
  content: "\e903";
}
.icon--account_remove:before {
  content: "\e904";
}
.icon--person_remove:before {
  content: "\e904";
}
.icon--account_check:before {
  content: "\e905";
}
.icon--person_check:before {
  content: "\e905";
}
.icon--account_alert:before {
  content: "\e906";
}
.icon--person_alert:before {
  content: "\e906";
}
.icon--account_key:before {
  content: "\e907";
}
.icon--person_key:before {
  content: "\e907";
}
.icon--account_star:before {
  content: "\e908";
}
.icon--person_star:before {
  content: "\e908";
}
.icon--account_edit:before {
  content: "\e909";
}
.icon--person_edit:before {
  content: "\e909";
}
.icon--account_search:before {
  content: "\e90a";
}
.icon--person_search:before {
  content: "\e90a";
}
.icon--account_off:before {
  content: "\e90b";
}
.icon--person_off:before {
  content: "\e90b";
}
.icon--account_group:before {
  content: "\e90c";
}
.icon--group:before {
  content: "\e90c";
}
.icon--people:before {
  content: "\e90c";
}
.icon--account_group_add:before {
  content: "\e90d";
}
.icon--group_add:before {
  content: "\e90d";
}
.icon--people_add:before {
  content: "\e90d";
}
.icon--account_group_remove:before {
  content: "\e90e";
}
.icon--group_remove:before {
  content: "\e90e";
}
.icon--people_remove:before {
  content: "\e90e";
}
.icon--account_switch:before {
  content: "\ea47";
}
.icon--account_supervisor:before {
  content: "\e90f";
}
.icon--supervisor_account:before {
  content: "\e90f";
}
.icon--add:before {
  content: "\e910";
}
.icon--add_circle_outline:before {
  content: "\e911";
}
.icon--add_circle:before {
  content: "\e912";
}
.icon--add_to_photos:before {
  content: "\e913";
}
.icon--alarm:before {
  content: "\e914";
}
.icon--application:before {
  content: "\e915";
}
.icon--apps:before {
  content: "\e916";
}
.icon--arrow_all:before {
  content: "\e917";
}
.icon--move:before {
  content: "\e917";
}
.icon--arrow_up:before {
  content: "\e918";
}
.icon--arrow_upward:before {
  content: "\e918";
}
.icon--arrow_up_right:before {
  content: "\e919";
}
.icon--arrow_right:before {
  content: "\e91a";
}
.icon--arrow_forward:before {
  content: "\e91a";
}
.icon--arrow_down_right:before {
  content: "\e91b";
}
.icon--arrow_down:before {
  content: "\e91c";
}
.icon--arrow_downward:before {
  content: "\e91c";
}
.icon--arrow_down_left:before {
  content: "\e91d";
}
.icon--arrow_left:before {
  content: "\e91e";
}
.icon--arrow_back:before {
  content: "\e91e";
}
.icon--arrow_up_left:before {
  content: "\e91f";
}
.icon--arrow_collapse_up:before {
  content: "\e920";
}
.icon--arrow_to_top:before {
  content: "\e920";
}
.icon--arrow_collapse_right:before {
  content: "\e921";
}
.icon--arrow_to_end:before {
  content: "\e921";
}
.icon--arrow_collapse_down:before {
  content: "\e922";
}
.icon--arrow_to_bottom:before {
  content: "\e922";
}
.icon--arrow_collapse_left:before {
  content: "\e923";
}
.icon--arrow_to_beginning:before {
  content: "\e923";
}
.icon--arrow_expand_downward:before {
  content: "\e924";
}
.icon--arrow_from_top:before {
  content: "\e924";
}
.icon--arrow_expand_left:before {
  content: "\e925";
}
.icon--arrow_from_right:before {
  content: "\e925";
}
.icon--arrow_expand_upward:before {
  content: "\e926";
}
.icon--arrow_from_bottom:before {
  content: "\e926";
}
.icon--arrow_expand_right:before {
  content: "\e927";
}
.icon--arrow_from_left:before {
  content: "\e927";
}
.icon--arrow_bounce_left:before {
  content: "\e928";
}
.icon--call_missed:before {
  content: "\e928";
}
.icon--arrow_bounce_right:before {
  content: "\e929";
}
.icon--call_missed_outgoing:before {
  content: "\e929";
}
.icon--arrow_collapse:before {
  content: "\e92a";
}
.icon--arrow_expand:before {
  content: "\e92b";
}
.icon--arrow_expand_all:before {
  content: "\e92c";
}
.icon--call_merge:before {
  content: "\e92d";
}
.icon--merge:before {
  content: "\e92d";
}
.icon--call_split:before {
  content: "\e92e";
}
.icon--split:before {
  content: "\e92e";
}
.icon--attach_file:before {
  content: "\e92f";
}
.icon--paperclip:before {
  content: "\e92f";
}
.icon--attachment:before {
  content: "\e92f";
}
.icon--broom:before {
  content: "\e9ee";
}
.icon--clean:before {
  content: "\e9ee";
}
.icon--bookmark:before {
  content: "\e930";
}
.icon--bookmark_add:before {
  content: "\e931";
}
.icon--bookmark_remove:before {
  content: "\e932";
}
.icon--bookmark_off:before {
  content: "\e933";
}
.icon--bookmark_outline:before {
  content: "\e934";
}
.icon--bookmark_outline_off:before {
  content: "\e935";
}
.icon--calendar:before {
  content: "\e936";
}
.icon--calendar_today:before {
  content: "\e937";
}
.icon--today:before {
  content: "\e937";
}
.icon--calendar_date_range:before {
  content: "\e938";
}
.icon--date_range:before {
  content: "\e938";
}
.icon--calendar_add:before {
  content: "\e939";
}
.icon--calendar_alert:before {
  content: "\e93a";
}
.icon--calendar_check:before {
  content: "\e93b";
}
.icon--event_available:before {
  content: "\e93b";
}
.icon--calendar_clock:before {
  content: "\e93c";
}
.icon--status_scheduled:before {
  content: "\e93c";
}
.icon--calendar_date:before {
  content: "\e93d";
}
.icon--event:before {
  content: "\e93d";
}
.icon--insert_invitation:before {
  content: "\e93d";
}
.icon--calendar_edit:before {
  content: "\e93e";
}
.icon--calendar_remove:before {
  content: "\e93f";
}
.icon--event_busy:before {
  content: "\e93f";
}
.icon--calendar_search:before {
  content: "\e940";
}
.icon--chart_arc:before {
  content: "\e941";
}
.icon--chart_areaspline:before {
  content: "\e942";
}
.icon--chart_bar:before {
  content: "\e943";
}
.icon--chart_bar_stacked:before {
  content: "\e944";
}
.icon--chart_bubble:before {
  content: "\e945";
}
.icon--chart_donut:before {
  content: "\e946";
}
.icon--donut_large:before {
  content: "\e946";
}
.icon--chart_gantt:before {
  content: "\e947";
}
.icon--chart_histogram:before {
  content: "\e948";
}
.icon--chart_line:before {
  content: "\e949";
}
.icon--chart_multiline:before {
  content: "\e94a";
}
.icon--chart_pie:before {
  content: "\e94b";
}
.icon--pie_chart:before {
  content: "\e94b";
}
.icon--chart_scatter_plot:before {
  content: "\e94c";
}
.icon--scatter_plot:before {
  content: "\e94c";
}
.icon--chart_timeline:before {
  content: "\e94d";
}
.icon--check:before {
  content: "\e94e";
}
.icon--checkbox_checked:before {
  content: "\e94f";
}
.icon--check_box:before {
  content: "\e94f";
}
.icon--checkbox_indeterminate:before {
  content: "\e950";
}
.icon--indeterminate_check_box:before {
  content: "\e950";
}
.icon--checkbox_unchecked:before {
  content: "\e951";
}
.icon--check_box_outline_blank:before {
  content: "\e951";
}
.icon--chevron_up:before {
  content: "\e952";
}
.icon--expand_less:before {
  content: "\e952";
}
.icon--keyboard_arrow_up:before {
  content: "\e952";
}
.icon--chevron_right:before {
  content: "\e953";
}
.icon--keyboard_arrow_right:before {
  content: "\e953";
}
.icon--chevron_down:before {
  content: "\e954";
}
.icon--expand_more:before {
  content: "\e954";
}
.icon--keyboard_arrow_down:before {
  content: "\e954";
}
.icon--chevron_left:before {
  content: "\e955";
}
.icon--keyboard_arrow_left:before {
  content: "\e955";
}
.icon--chevron_double_up:before {
  content: "\e956";
}
.icon--chevron_double_right:before {
  content: "\e957";
}
.icon--chevron_double_down:before {
  content: "\e958";
}
.icon--chevron_double_left:before {
  content: "\e959";
}
.icon--chevron_to_beginning:before {
  content: "\e95a";
}
.icon--page_first:before {
  content: "\e95a";
}
.icon--chevron_to_end:before {
  content: "\e95b";
}
.icon--page_last:before {
  content: "\e95b";
}
.icon--clock:before {
  content: "\e95c";
}
.icon--time:before {
  content: "\e95c";
}
.icon--schedule:before {
  content: "\e95c";
}
.icon--close:before {
  content: "\e95d";
}
.icon--clear:before {
  content: "\e95d";
}
.icon--color_palette:before {
  content: "\e95e";
}
.icon--color_lens:before {
  content: "\e95e";
}
.icon--compass:before {
  content: "\e95f";
}
.icon--content_copy:before {
  content: "\e960";
}
.icon--content_cut:before {
  content: "\e961";
}
.icon--content_paste:before {
  content: "\e962";
}
.icon--clipboard:before {
  content: "\e962";
}
.icon--content_save:before {
  content: "\e963";
}
.icon--save:before {
  content: "\e963";
}
.icon--content_save_alt:before {
  content: "\e964";
}
.icon--save_alt:before {
  content: "\e964";
}
.icon--cookie:before {
  content: "\e965";
}
.icon--decimal_decrease:before {
  content: "\e966";
}
.icon--decimal_increase:before {
  content: "\e967";
}
.icon--delete:before {
  content: "\e968";
}
.icon--delete_empty:before {
  content: "\e969";
}
.icon--delete_sweep:before {
  content: "\e96a";
}
.icon--device_monitor_cellphone:before {
  content: "\e96b";
}
.icon--device_monitor:before {
  content: "\e96c";
}
.icon--monitor:before {
  content: "\e96c";
}
.icon--device_laptop:before {
  content: "\e96d";
}
.icon--laptop:before {
  content: "\e96d";
}
.icon--computer:before {
  content: "\e96d";
}
.icon--device_tablet:before {
  content: "\e96e";
}
.icon--tablet:before {
  content: "\e96e";
}
.icon--tablet_android:before {
  content: "\e96e";
}
.icon--tablet_mac:before {
  content: "\e96e";
}
.icon--device_smartphone:before {
  content: "\e96f";
}
.icon--smartphone:before {
  content: "\e96f";
}
.icon--camera:before {
  content: "\e970";
}
.icon--camera_alt:before {
  content: "\e970";
}
.icon--device_phone:before {
  content: "\e971";
}
.icon--phone:before {
  content: "\e971";
}
.icon--device_database:before {
  content: "\e972";
}
.icon--database:before {
  content: "\e972";
}
.icon--device_server:before {
  content: "\e973";
}
.icon--server:before {
  content: "\e973";
}
.icon--dialpad:before {
  content: "\e974";
}
.icon--diamond:before {
  content: "\e975";
}
.icon--drag:before {
  content: "\e976";
}
.icon--drag_horizontal:before {
  content: "\e977";
}
.icon--drag_vertical:before {
  content: "\e978";
}
.icon--earth:before {
  content: "\ea4a";
}
.icon--edit:before {
  content: "\e979";
}
.icon--pencil:before {
  content: "\e979";
}
.icon--email:before {
  content: "\e97a";
}
.icon--mail:before {
  content: "\e97a";
}
.icon--email_open:before {
  content: "\e97b";
}
.icon--drafts:before {
  content: "\e97b";
}
.icon--email_outline:before {
  content: "\e97c";
}
.icon--mail_outline:before {
  content: "\e97c";
}
.icon--email_open_outline:before {
  content: "\e97d";
}
.icon--exposure:before {
  content: "\e97e";
}
.icon--file_multiple:before {
  content: "\e97f";
}
.icon--file:before {
  content: "\e980";
}
.icon--file_outline:before {
  content: "\e981";
}
.icon--file_hidden:before {
  content: "\e982";
}
.icon--file_alert:before {
  content: "\e983";
}
.icon--file_chart:before {
  content: "\e984";
}
.icon--file_check:before {
  content: "\e985";
}
.icon--file_excel:before {
  content: "\e986";
}
.icon--file_find:before {
  content: "\e987";
}
.icon--file_image:before {
  content: "\e988";
}
.icon--file_pdf:before {
  content: "\e989";
}
.icon--file_powerpoint:before {
  content: "\e98a";
}
.icon--file_word:before {
  content: "\e98b";
}
.icon--file_xml:before {
  content: "\e98c";
}
.icon--file_tree:before {
  content: "\e98d";
}
.icon--file_upload:before {
  content: "\e98e";
}
.icon--upload:before {
  content: "\e98e";
}
.icon--file_download:before {
  content: "\e98f";
}
.icon--download:before {
  content: "\e98f";
}
.icon--filter:before {
  content: "\e990";
}
.icon--filter_list:before {
  content: "\e991";
}
.icon--filter_remove:before {
  content: "\e992";
}
.icon--fingerprint:before {
  content: "\e993";
}
.icon--flag:before {
  content: "\e994";
}
.icon--flag_outline:before {
  content: "\e995";
}
.icon--folder:before {
  content: "\e996";
}
.icon--folder_add:before {
  content: "\e997";
}
.icon--folder_lock:before {
  content: "\e998";
}
.icon--folder_multiple:before {
  content: "\e999";
}
.icon--folder_open:before {
  content: "\e99a";
}
.icon--folder_outline:before {
  content: "\e99b";
}
.icon--fullscreen:before {
  content: "\e99c";
}
.icon--fullscreen_exit:before {
  content: "\e99d";
}
.icon--gauge:before {
  content: "\e99e";
}
.icon--golf:before {
  content: "\e99f";
}
.icon--grid_on:before {
  content: "\e9a0";
}
.icon--grid:before {
  content: "\e9a0";
}
.icon--home_outline:before {
  content: "\e9a1";
}
.icon--home:before {
  content: "\e9a2";
}
.icon--image:before {
  content: "\e9a3";
}
.icon--image_add:before {
  content: "\e9a4";
}
.icon--image_multiple:before {
  content: "\e9a5";
}
.icon--photo_library:before {
  content: "\e9a5";
}
.icon--insert_chart:before {
  content: "\e9a6";
}
.icon--key:before {
  content: "\e9a7";
}
.icon--label:before {
  content: "\e9a8";
}
.icon--label_off:before {
  content: "\e9a9";
}
.icon--label_outline:before {
  content: "\e9aa";
}
.icon--label_off_outline:before {
  content: "\e9ab";
}
.icon--launch:before {
  content: "\e9ac";
}
.icon--lifebuoy:before {
  content: "\e9ad";
}
.icon--lightbulb_on:before {
  content: "\e9ae";
}
.icon--lightbulb:before {
  content: "\e9af";
}
.icon--link:before {
  content: "\e9b0";
}
.icon--link_off:before {
  content: "\e9b1";
}
.icon--lock:before {
  content: "\e9b2";
}
.icon--lock_open:before {
  content: "\e9b3";
}
.icon--lock_open_outline:before {
  content: "\e9b4";
}
.icon--lock_outline:before {
  content: "\e9b5";
}
.icon--map_marker:before {
  content: "\e9b6";
}
.icon--place:before {
  content: "\e9b6";
}
.icon--map_marker_off:before {
  content: "\e9b7";
}
.icon--place_off:before {
  content: "\e9b7";
}
.icon--media_closed_caption:before {
  content: "\e9b8";
}
.icon--closed_caption:before {
  content: "\e9b8";
}
.icon--media_skip_previous:before {
  content: "\e9b9";
}
.icon--skip_previous:before {
  content: "\e9b9";
}
.icon--media_rewind:before {
  content: "\e9ba";
}
.icon--rewind:before {
  content: "\e9ba";
}
.icon--media_play:before {
  content: "\e9bb";
}
.icon--play_arrow:before {
  content: "\e9bb";
}
.icon--media_fast_forward:before {
  content: "\e9bc";
}
.icon--fast_forward:before {
  content: "\e9bc";
}
.icon--media_skip_next:before {
  content: "\e9bd";
}
.icon--skip_next:before {
  content: "\e9bd";
}
.icon--media_pause:before {
  content: "\e9be";
}
.icon--pause:before {
  content: "\e9be";
}
.icon--media_stop:before {
  content: "\e9bf";
}
.icon--stop:before {
  content: "\e9bf";
}
.icon--media_record:before {
  content: "\e9c0";
}
.icon--fiber_manual_record:before {
  content: "\e9c0";
}
.icon--media_mic:before {
  content: "\e9c1";
}
.icon--mic:before {
  content: "\e9c1";
}
.icon--media_mic_off:before {
  content: "\e9c2";
}
.icon--mic_off:before {
  content: "\e9c2";
}
.icon--media_video:before {
  content: "\e9c3";
}
.icon--videocam:before {
  content: "\e9c3";
}
.icon--media_video_off:before {
  content: "\e9c4";
}
.icon--videocam_off:before {
  content: "\e9c4";
}
.icon--play_circle_outline:before {
  content: "\ea48";
}
.icon--pause_circle_outline:before {
  content: "\ea49";
}
.icon--media_volume_low:before {
  content: "\e9c5";
}
.icon--volume_mute:before {
  content: "\e9c5";
}
.icon--media_volume_mid:before {
  content: "\e9c6";
}
.icon--volume_down:before {
  content: "\e9c6";
}
.icon--media_volume_high:before {
  content: "\e9c7";
}
.icon--volume_up:before {
  content: "\e9c7";
}
.icon--media_volume_off:before {
  content: "\e9c8";
}
.icon--volume_off:before {
  content: "\e9c8";
}
.icon--menu:before {
  content: "\e9c9";
}
.icon--menu_dots:before {
  content: "\e9ca";
}
.icon--dots_vertical:before {
  content: "\e9ca";
}
.icon--menu_down:before {
  content: "\e9cb";
}
.icon--arrow_drop_down:before {
  content: "\e9cb";
}
.icon--menu_left:before {
  content: "\e9cc";
}
.icon--menu_right:before {
  content: "\e9cd";
}
.icon--menu_up:before {
  content: "\e9ce";
}
.icon--arrow_drop_up:before {
  content: "\e9ce";
}
.icon--menu_up_down:before {
  content: "\e9cf";
  color: #565a5c;
}
.icon--menu_swap:before {
  content: "\e9cf";
  color: #565a5c;
}
.icon--message:before {
  content: "\e9d0";
}
.icon--chat_bubble:before {
  content: "\e9d0";
}
.icon--message_add:before {
  content: "\e9d1";
}
.icon--message_alert:before {
  content: "\e9d2";
}
.icon--feedback:before {
  content: "\e9d2";
}
.icon--announcement:before {
  content: "\e9d2";
}
.icon--message_alert_outline:before {
  content: "\e9d3";
}
.icon--message_off:before {
  content: "\e9d4";
}
.icon--message_outline:before {
  content: "\e9d5";
}
.icon--chat_bubble_outline:before {
  content: "\e9d5";
}
.icon--message_processing:before {
  content: "\e9d6";
}
.icon--texsms:before {
  content: "\e9d6";
}
.icon--message_reply:before {
  content: "\e9d7";
}
.icon--message_reply_text:before {
  content: "\e9d8";
}
.icon--comment:before {
  content: "\e9d8";
}
.icon--message_text:before {
  content: "\e9d9";
}
.icon--chat:before {
  content: "\e9d9";
}
.icon--message_text_outline:before {
  content: "\e9da";
}
.icon--bank:before {
  content: "\e9f3";
}
.icon--place_institution:before {
  content: "\e9f3";
}
.icon--account_balance:before {
  content: "\e9f3";
}
.icon--bank_transfer_in:before {
  content: "\ea44";
}
.icon--bank_transfer_out:before {
  content: "\ea45";
}
.icon--bank_transfer:before {
  content: "\ea46";
}
.icon--checkbook:before {
  content: "\e9db";
}
.icon--money_bill:before {
  content: "\e9dc";
}
.icon--money_bills:before {
  content: "\e9dd";
}
.icon--credit_card:before {
  content: "\e9de";
}
.icon--credit_card_add:before {
  content: "\e9df";
}
.icon--credit_card_multiple:before {
  content: "\e9e0";
}
.icon--credit_card_off:before {
  content: "\e9e1";
}
.icon--currency_dollar:before {
  content: "\e9e2";
}
.icon--attach_money:before {
  content: "\e9e2";
}
.icon--currency_sign:before {
  content: "\e9e3";
}
.icon--currency_dollar_off:before {
  content: "\e9e4";
}
.icon--printer:before {
  content: "\ea4c";
}
.icon--print:before {
  content: "\ea4c";
}
.icon--money_receipt:before {
  content: "\e9e5";
}
.icon--outline_receipt:before {
  content: "\e9e5";
}
.icon--move_horizontally:before {
  content: "\e9e6";
}
.icon--move_vertically:before {
  content: "\e9e7";
}
.icon--not_interested:before {
  content: "\e9e8";
}
.icon--notifications_active:before {
  content: "\e9e9";
}
.icon--notifications:before {
  content: "\e9ea";
}
.icon--notifications_none:before {
  content: "\e9eb";
}
.icon--notifications_off:before {
  content: "\e9ec";
}
.icon--notifications_paused:before {
  content: "\e9ed";
}
.icon--pen:before {
  content: "\e9ef";
}
.icon--pin:before {
  content: "\e9f0";
}
.icon--pin_off:before {
  content: "\e9f1";
}
.icon--place_buildings:before {
  content: "\e9f2";
}
.icon--domain:before {
  content: "\e9f2";
}
.icon--radar:before {
  content: "\e9f4";
}
.icon--track_changes:before {
  content: "\e9f4";
}
.icon--radio_button_checked:before {
  content: "\e9f5";
}
.icon--radio_checked:before {
  content: "\e9f5";
}
.icon--radio_button_unchecked:before {
  content: "\e9f6";
}
.icon--radio_unchecked:before {
  content: "\e9f6";
}
.icon--reminder:before {
  content: "\e9f7";
}
.icon--remove:before {
  content: "\e9f8";
}
.icon--repeat:before {
  content: "\e9f9";
}
.icon--repeat_off:before {
  content: "\e9fa";
}
.icon--restore:before {
  content: "\e9fb";
}
.icon--settings_backup_restore:before {
  content: "\e9fb";
}
.icon--search:before {
  content: "\e9fc";
}
.icon--magnifying_glass:before {
  content: "\e9fc";
}
.icon--security:before {
  content: "\e9fd";
}
.icon--security_verified:before {
  content: "\e9fe";
}
.icon--mood_good:before {
  content: "\e9ff";
}
.icon--mood:before {
  content: "\e9ff";
}
.icon--sentiment_satisfied:before {
  content: "\ea00";
}
.icon--sentiment_neutral:before {
  content: "\ea01";
}
.icon--sentiment_dissatisfied:before {
  content: "\ea02";
}
.icon--mood_bad:before {
  content: "\ea03";
}
.icon--sentiment_very_satisfied:before {
  content: "\ea04";
}
.icon--sentiment_very_dissatisfied:before {
  content: "\ea05";
}
.icon--settings:before {
  content: "\ea06";
}
.icon--share:before {
  content: "\ea07";
}
.icon--sort:before {
  content: "\ea08";
}
.icon--sort_asc:before {
  content: "\ea09";
}
.icon--sort_desc:before {
  content: "\ea0a";
}
.icon--star:before {
  content: "\ea0b";
}
.icon--favorite_on:before {
  content: "\ea0b";
}
.icon--star_half:before {
  content: "\ea0c";
}
.icon--star_outline:before {
  content: "\ea0d";
}
.icon--star_border:before {
  content: "\ea0d";
}
.icon--favorite_off:before {
  content: "\ea0d";
}
.icon--star_off:before {
  content: "\ea0e";
}
.icon--state_history:before {
  content: "\ea0f";
}
.icon--history:before {
  content: "\ea0f";
}
.icon--state_undo:before {
  content: "\ea43";
}
.icon--undo:before {
  content: "\ea43";
}
.icon--state_redo:before {
  content: "\ea10";
}
.icon--redo:before {
  content: "\ea10";
}
.icon--bug:before {
  content: "\ea11";
}
.icon--bug_check:before {
  content: "\ea12";
}
.icon--status_error:before {
  content: "\ea13";
}
.icon--warning:before {
  content: "\ea13";
}
.icon--report_problem:before {
  content: "\ea13";
}
.icon--status_error_outline:before {
  content: "\ea14";
}
.icon--alert_outline:before {
  content: "\ea14";
}
.icon--status_help:before {
  content: "\ea15";
}
.icon--help:before {
  content: "\ea15";
}
.icon--status_help_outline:before {
  content: "\ea16";
}
.icon--help_outline:before {
  content: "\ea16";
}
.icon--status_info:before {
  content: "\ea17";
}
.icon--status_info_square:before {
  content: "\ea5e";
}
.icon--info:before {
  content: "\ea17";
}
.icon--status_info_outline:before {
  content: "\ea18";
}
.icon--info_outline:before {
  content: "\ea18";
}
.icon--status_offline:before {
  content: "\ea19";
}
.icon--offline_bolt:before {
  content: "\ea19";
}
.icon--status_on_target:before {
  content: "\ea1a";
}
.icon--bullseye:before {
  content: "\ea1a";
}
.icon--target:before {
  content: "\ea1a";
}
.icon--status_processing:before {
  content: "\ea1b";
}
.icon--cached:before {
  content: "\ea1b";
}
.icon--status_periodic:before {
  content: "\ea1c";
}
.icon--status_refresh:before {
  content: "\ea1d";
}
.icon--refresh:before {
  content: "\ea1d";
}
.icon--status_rejected:before {
  content: "\ea1e";
  color: #565a5c;
}
.icon--cancel:before {
  content: "\ea1e";
  color: #565a5c;
}
.icon--status_rejected_outline:before {
  content: "\ea1f";
}
.icon--cancel_outline:before {
  content: "\ea1f";
}
.icon--status_validated:before {
  content: "\ea20";
}
.icon--check_circle:before {
  content: "\ea20";
}
.icon--status_validated_outline:before {
  content: "\ea21";
}
.icon--check_circle_outline:before {
  content: "\ea21";
}
.icon--status_warning:before {
  content: "\ea22";
}
.icon--error:before {
  content: "\ea22";
}
.icon--status_warning_outline:before {
  content: "\ea23";
}
.icon--error_outline:before {
  content: "\ea23";
}
.icon--swap_horiz:before {
  content: "\ea24";
}
.icon--swap_vert:before {
  content: "\ea25";
}
.icon--swap_vertical_circle:before {
  content: "\ea26";
}
.icon--sync:before {
  content: "\ea27";
}
.icon--sync_alert:before {
  content: "\ea28";
}
.icon--sync_off:before {
  content: "\ea29";
}
.icon--table:before {
  content: "\ea2a";
}
.icon--table_edit:before {
  content: "\ea2b";
}
.icon--table_large:before {
  content: "\ea2c";
}
.icon--team:before {
  content: "\ea2d";
}
.icon--thumb_down_outline:before {
  content: "\ea2e";
}
.icon--thumb_up_outline:before {
  content: "\ea2f";
}
.icon--timelapse:before {
  content: "\ea30";
}
.icon--trending_down:before {
  content: "\ea31";
}
.icon--trending_neutral:before {
  content: "\ea32";
}
.icon--trending_up:before {
  content: "\ea33";
}
.icon--view_columns:before {
  content: "\ea34";
}
.icon--view_dashboard:before {
  content: "\ea35";
}
.icon--view_grid:before {
  content: "\ea36";
}
.icon--view_list:before {
  content: "\ea4b";
}
.icon--view_rows:before {
  content: "\ea37";
}
.icon--visibility:before {
  content: "\ea38";
}
.icon--visibility_off:before {
  content: "\ea39";
}
.icon--visibility_outline:before {
  content: "\ea3a";
}
.icon--visibility_off_outline:before {
  content: "\ea3b";
}
.icon--wifi:before {
  content: "\ea3c";
}
.icon--wifi_off:before {
  content: "\ea3d";
}
.icon--work:before {
  content: "\ea3e";
}
.icon--briefcase:before {
  content: "\ea3e";
}
.icon--work_outline:before {
  content: "\ea3f";
}
.icon--briefcase_outline:before {
  content: "\ea3f";
}
.icon--zip_box:before {
  content: "\ea40";
}
.icon--zoom_in:before {
  content: "\ea41";
}
.icon--magnifying_glass_zoom_in:before {
  content: "\ea41";
}
.icon--zoom_out:before {
  content: "\ea42";
}
.icon--magnifying_glass_zoom_out:before {
  content: "\ea42";
}