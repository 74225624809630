// Overrides for the ag view theme
.ag-theme-views {
	.ag-header {
		height: 3.2rem !important;
		min-height: 3.2rem !important;
	}

	.ag-header-cell-label {
		align-items: center;
		line-height: 1em;

		.ag-header-icon {
			display: inline-flex;
			height: auto;
		}
	}

	.ag-header-cell-resize {
		&::after {
			border-right: 0.1rem solid $mat-grey-300;
			border-right-width: 0.1rem;
			border-right-style: solid;
			border-right-color: $border_right_color_1;
			box-sizing: content-box;
			content: "resize";
			display: block;
			height: 2.4rem;
			margin-top: 1.6rem;
			overflow: hidden;
			text-indent: 0.8rem;
			width: 0.8rem;
			height: 2.2rem;
			margin-top: 0.9rem;
		}
	}

	.ag-menu {
		.ag-menu-option>span {
			&>.ag-icon {
				margin-top: 0;
				vertical-align: middle;
			}
		}
	}

	.ag-group-value {
		display: inline-flex;
	}
}

.ag-theme-material {

	.ag-header-cell-resize {
		z-index: 0;

		&::after {
			border-right: 0.1rem solid $mat-grey-300;
			border-right-width: 0.1rem;
			border-right-style: solid;
			border-right-color: $border_right_color_1;
			box-sizing: content-box;
			content: "resize";
			display: block;
			height: 2.4rem;
			margin-top: 1.6rem;
			overflow: hidden;
			text-indent: 0.8rem;
			width: 0.8rem;
			height: 2.2rem;
			margin-top: 0.9rem;
		}
	}
}

.ag-frequency-grid-views {
	.ag-header {
		height: 9.6rem !important;
		min-height: 9.6rem !important;
	}

	.ag-header-row:nth-child(1),
	.ag-header-row:nth-child(2) {
		border-bottom: 0.1rem solid $mat-grey-300 !important;
	}

	.ag-header-row:nth-child(2) {
		top: 3.2rem !important;
	}

	.ag-header-row:nth-child(3) {
		top: 6.4rem !important;
	}

	.ag-header-cell-menu-button {
		.ag-icon-menu {
			height: 3.2rem !important;
		}
	}

	.ag-header-cell.ag-header-cell-sortable,
	.ag-pinned-left-header,
	.ag-header-group-cell.ag-header-group-cell-with-group {
		border-right: 0.1rem solid $mat-grey-300 !important;
		border-top: none;
	}

	span.investments-label,
	span.ag-header-group-text {
		font-size: 1.2rem;
	}
}

.ag-theme-views,
.ag-theme-material {
	.ag-header-row {
		border-bottom: 0.1rem solid $border_right_color_1;

		&:last-of-type {
			border-bottom: none;
		}
	}
}

.ag-theme-views,
.ag-theme-material,
.ag-frequency-grid-views {
	font-family: $font_family_1 !important;
	color:  #3D4042 !important;

	.ag-header-row {
		height: 3.2rem !important;
	}

	.ag-header-cell {
		line-height: 3.2rem;
	}

	.ag-header-cell-menu-button {
		.ag-icon-menu {
			height: 3.2rem;
		}
	}

	.ag-status-bar {
		background: color(NTWarmGrey, 100);
		border: 0 solid color(NTDarkGrey, 300);
	}

	.ag-header {
		border-bottom: 0.1rem solid $color_1;
	}

	.ag-tooltip {
		font-family: $font_family_1;
		color: $background_color_3;
		background-color: $color_1;
		padding: 0.6rem 0.8rem 0.6rem 0.8rem;
		max-width: 40rem;
	}

	.ag-pinned-left-header {
		border-right: none;
	}

	.ag-header-cell {
		padding-left: 0.8rem;
		padding-right: 1.3rem;
	}

	.ag-header-group-cell {
		line-height: 3.2rem;
		padding-left: 0.8rem;
		padding-right: 1.3rem;

		&:not(.ag-column-resizing)+.ag-header-group-cell,
		&:first-of-type {
			&:hover {
				background-color: inherit;
			}
		}
	}

	.ag-header-cell,
	.ag-header-group-cell {
		&:not(.ag-header-group-cell-no-group) {
			border-top-width: 0;
			border-top: 0 !important;
		}
	}

	.ag-header-cell-label,
	.ag-header-group-cell-label {
		.investments-label {
			font-family: $font_family_1;
			font-weight: 500;
			color: $color_1;
		}

		.ag-header-cell-text,
		.ag-header-group-text {
			font-family: $font_family_2;
			font-weight: 500;
			color: $color_1;
		}
	}

	.ag-icon-checkbox-checked-readonly,
	.ag-icon-checkbox-unchecked-readonly,
	.ag-icon-checkbox-indeterminate-readyonly {
		opacity: 0.4;
	}

	.ag-column-select-panel {
		.ag-column-select-column-group {
			.ag-column-select-checkbox,
			.ag-column-group-icons {
				padding-top: 0.5rem;
			}
		}

		.ag-column-select-column {
			.ag-column-select-checkbox,
			.ag-column-group-icons {
				padding-top: 0.5rem;
			}
		}
	}

	.ag-tab-header {
		.ag-tab.ag-tab-selected {
			border-bottom: 0.2rem solid color(green, 600);
		}
	}

	.ag-menu {
		.ag-menu-option-disabled {
			opacity: 0.4;
		}

		border-radius: 0 !important;
		box-shadow: rgba(0, 0, 0, 0.2) 0 0.3rem 0.5rem 0 !important;
		transition: opacity 420ms cubic-bezier(0.4, 0, 0.2, 1) 100ms,
		transform 280ms cubic-bezier(0.4, 0, 0.2, 1) 100ms !important;
		transform-origin: 26rem 7.8rem 0;
		border: 0.1rem solid $elementDivider;

		.ag-menu-option-active {
			background: color(NTDarkGrey, 50);
		}
	}

	.ag-column-drag {
		min-width: 2.1rem;
		background-image: url(../assets/img/ag-icons/ag-menu-drag.svg) !important;
	}

	.ag-column-panel {
		border-radius: 0 !important;
		box-shadow: rgba(0, 0, 0, 0.2) 0 0.3rem 0.5rem 0 !important;
		transition: opacity 420ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, transform 280ms cubic-bezier(0.4, 0, 0.2, 1) 100ms !important;
		transform-origin: 26rem 7.8rem 0;
		border: 0.1rem solid $elementDivider;
	}

	.ag-tool-panel {
		.ag-side-buttons {
			border: 0;
		}

		background-color: $background_color_3;
	}

	.ag-floating-filter-body {
		input {
			&:focus {
				border-bottom: 0.2rem solid color(green, 600) !important;
			}
		}
	}

	.ag-tab-body {
		.ag-menu-list {
			>div {
				&:last-child {
					>.ag-menu-option-popup-pointer {
						background-position-y: 50% !important;
					}
				}
			}
		}
	}

	.ag-filter {
		input[type=date]:focus,
		input[type=text]:focus {
			border-bottom: 0.2rem solid color(green, 600);
		}

		.ag-filter-apply-panel {
			button {
				border-bottom: 0.2rem solid color(green, 600);
			}
		}
	}

	.ag-popup-editor {
		.ag-large-textarea {
			textarea {
				&:focus {
					border-bottom: 0.2rem solid color(green, 600);
				}
			}
		}
	}

	.ag-floating-filter-full-body {
		input {
			&:focus {
				border-bottom: 0.2rem solid color(green, 600);
			}
		}
	}

	.ag-column-name-filter {
		&:focus {
			border-bottom: 0.2rem solid color(green, 600);
		}
	}

	.ag-cell-inline-editing {
		input[type=date]:focus,
		input[type=datetime-local]:focus,
		input[type=tel]:focus,
		input[type=text]:focus {
			border-bottom: 0.2rem solid color(green, 600);
		}
	}

	.ag-cell-range-selected {
		&:not(.ag-cell-focus) {
			background-color: $background_color_4;
		}
	}

	.ag-row {
		border: none;
	}

	.ag-ltr {

		.ag-row.ag-cell-last-left-pinned,
		.ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned,
		.ag-cell-last-left-pinned {
			border-right: none;
		}

		.ag-cell-focus {
			box-shadow: inset 0 0 0 0.1rem $green_500 !important;
			border: none !important;
			outline: initial;
		}

		.ag-row-group-leaf-indent {
			&:not(.ag-row-level-0) {
				margin-left: 2.4rem;
			}
		}

		.benchmarkCell {
			.ag-row-group-leaf-indent {
				margin-left: 0 !important;
			}
		}

		.ag-row-group-indent-1 {
			margin-left: 2.5rem;
			padding-left: 1.6rem;
		}

		.ag-row-group-indent-2 {
			margin-left: 5.5rem;
			padding-left: 4.5rem;
		}
	}

	.ag-row-hover {
		background-color: $background_color_5;
	}

	.ag-cell {
		display: list-item;
		font-size: 1.3rem;
		line-height: 3.4rem;
		padding: 0 0.5rem;
		border: none;

		p {
			margin: 0;
			padding-left: 0.5rem;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&::before {
			content: "";
			display: block;
			background: color(NTDarkGrey, 200);
			position: absolute;
			height: 0.1rem;
			bottom: 0;
			left: 0.5rem;
			right: 0.5rem;
			z-index: -1;
		}

		&.ag-cell-last-left-pinned {
			line-height: 4rem;
		}
	}

	.ag-group-checkbox {
		float: left;
		padding-top: 0.2rem;
		padding-right: 0.8rem;
	}

	.ag-group-expanded {
		.ag-icon-contracted, .ag-icon-tree-closed {
			&:empty {
				height: 1rem;
			}
		}

		.ag-icon {
			display: inline;
		}
	}

	.ag-selection-checkbox {
		span {
			height: 1rem;
		}
	}

	.ag-group-contracted {

		.ag-icon {
			display: inline
		}
	}

	.ag-group-expanded,
	.ag-group-contracted {
		cursor: default;
		&:not(.ag-hidden) {
			&~.ag-group-checkbox {
				margin-left: 0;
			}
		}
	}
}

.ag-ltr {
	.ag-header-cell-resize {
		right: -0.7rem;
	}

	.ag-tool-panel-horizontal-resize {
		border-right: 0.3rem solid $elementDivider;
	}

	.no-resize {
		pointer-events: none;
	}
}

.ag-theme-material {
	.ag-tab-header {
		border-bottom: 0.1rem solid color(NTDarkGrey, 200);
	}
}

.ag-react-container {
	padding: 0.3rem;
	height: 100%;
}

.ag-row {
	div[col-id=classification-group-column] {
		&.ag-cell-focus {
			box-shadow: none !important;
		}

		text-overflow: unset;

		.ag-react-container {
			display: inline-block;

			.grouping-container {
				display: flex;

				.grouping-name {
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}

		&.classificationCell {
			.ag-group-value {
				padding-left: 0 !important;

				.grouping-container {
					padding: 0.3rem 1rem;
					border-radius: 1.5rem;
					background-color: $background_color_6;
					line-height: 1.3rem;
					font-size: 1.3rem;

					&.in-card {
						line-height: 1.2rem;
						font-size: 1.2rem;
					}

					.grouping-count {
						font-family: $font_family_1;
						font-weight: 400;
						font-size: 1.2rem;
						color: $color_4;
						padding-left: 0.6rem;
					}
				}
			}
		}

		&:not(.classificationCell) {
			.ag-react-container {
				padding: 0;
			}
		}
	}
}
